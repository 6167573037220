<template lang="pug">
.content
  .vertical-time-line
    .step
      .circle-holder
        .number 1
      .d-flex.container
        .title {{ $t('integrationFlow.hubSpotV2.authentication') }}
        .description.d-flex.align-items-center
          template(v-if="hasOauthError")
            UilTimesCircle(size="1.25em" color="#E4252D")
            .auth-success.ml-1 {{ $t('integrationFlow.hubSpotV2.authError') }}
          template(v-else-if="hasAccessToken")
            UilCheckCircle(size="1.25em" color="#2CC896")
            .auth-success.ml-1 {{ $t('integrationFlow.hubSpotV2.authSuccessful') }}
          template(v-else)
            om-button(secondary icon="userCheck" @click="authenticate") {{ $t('integrationFlow.hubSpotV2.authBtn') }}
    .step
      .circle-holder
        .number 2
      .d-flex.container
        .title {{ $t('integrationFlow.integrationModal.general.addName') }}
        .description
          .input-holder
            om-input#name.mb-auto.w-100(
              v-model.trim="settings.name"
              :disabled="!!hasOauthError"
              type="text"
              :placeholder="$t('integrationFlow.integrationModal.general.namePlaceholder')"
              :helpText="$t('integrationFlow.integrationModal.general.inputHelp')"
            )
              template(#error v-if="validations.name.$error && !validations.name.required")
                span {{ $t('requiredField') }}
</template>
<script>
  import { UilCheckCircle, UilTimesCircle } from '@iconscout/vue-unicons';
  import { getOauthRedirectUrl } from '@/utils/oauth';

  export default {
    components: {
      UilCheckCircle,
      UilTimesCircle,
    },
    props: {
      settings: {
        type: Object,
        required: true,
      },

      validations: {
        type: Object,
        required: true,
      },

      isNew: {
        type: Boolean,
        default: true,
      },
    },
    computed: {
      hasOauthError() {
        return !!this.$route.query?.oauthError;
      },
      getBaseIntegrationData() {
        return {
          type: 'hubSpotV2',
          data: {
            name: this.settings.name || 'HubSpot Integration {counter}',
          },
        };
      },
      hasAccessToken() {
        return !!this.settings.access_token;
      },
    },
    methods: {
      authenticate() {
        this.$bus.$emit('saveCampaignSettings', { redirect: null });
        window.location.href = getOauthRedirectUrl(
          this.getBaseIntegrationData,
          this.$route.params.id,
        );
      },
    },
  };
</script>
