import { IntegrationService } from '@/services/integrations/integrationService';

class HubSpotIntegrationService extends IntegrationService {
  async fetchListAndFields(apollo, settings) {
    const integrationService = new IntegrationService(apollo);
    const result = {
      lists: [],
      fields: [],
    };

    try {
      const { lists, fields } = await integrationService.fetchIntegrationData(
        settings.type,
        settings.id,
      );

      if (fields.length > 0) {
        result.lists = lists.map((list) => ({
          key: list.id,
          value: list.name,
        }));
        result.fields = fields.map((field) => ({
          id: field.id,
          name: field.name,
          type: field.type,
        }));
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }

    return result;
  }
}

export { HubSpotIntegrationService };
